<template>
  <div>
    <b-row class="border-bottom">
      <b-col md="4" class="my-1">
        <h2><b>ระบบเบิกค่าใช้จ่าย (ฝ่ายบัญชี)</b></h2>
        <small>ระบบเบิกค่าใช้จ่าย (ฝ่ายบัญชี) > รายละเอียดการเบิก</small>
      </b-col>
      <b-col md="8" class="my-1">
        <h3 class="d-flex justify-content-end">
          <b>เลขที่ {{ incentiveOtData.otherNo }}</b>
        </h3>
        <h4 class="d-flex justify-content-end">
          วันที่ {{ incentiveOtData.otherDate }}
        </h4>
      </b-col>
    </b-row>

    <!-- Start Toolbar-->
    <b-row>
      <b-col md="6" class="my-1">
        <b-form inline class="d-flex justify-content-start">
          <!-- <v-select
            style="width: 15%"
            id="brand"
            v-model="kiloRatioSelected"
            label="title"
            :options="kiloRatioOption"
            :clearable="false"
          /> -->
          <b-form-input
              id="smallInput"
              placeholder="ค่ากิโล"             
              v-model.number="kiloRatioSelected"
            />

          <b-button
            v-if="accountAdminPermission=='edit'"
            variant="outline-primary"
            class="ml-1 shadow"
            @click="computeRecalKm"
            :disabled="isRecalKmLoading"
          >
            <span v-if="isRecalKmLoading">กำลังคำนวณ...</span>
            <span v-else>คำนวณกิโล</span>
          </b-button>
        </b-form>
      </b-col>
      <b-col md="6" class="my-1 d-flex justify-content-end">
        <b-form inline class="d-flex justify-content-end">
          <!-- <b-button variant="outline-dark" class="ml-1 shadow"
            ><feather-icon icon="RefreshCcwIcon" size="14" /> Re-Calculate
          </b-button> -->
          <div v-if="accountAdminPermission=='edit'">
          <b-button
            @click="$router.push(`/accounting/admin/clearbill/${$route.params.incentiveId}`)"
            variant="outline-dark"
            class="ml-1 shadow"
            ><feather-icon icon="Edit2Icon" size="14" /> แก้ไข
          </b-button>
          <b-button
            variant="primary"
            class="mx-1 shadow"
            @click="updateIncentiveOtProcess('approve')"
            :disabled="isAprroveLoading"
          >
            <span v-if="isAprroveLoading"
              ><feather-icon icon="CheckIcon" size="14" /> กำลังอนุมัติ...</span
            >
            <span v-else>อนุมัติ</span>
          </b-button>
          <b-button
            variant="danger"
            class="ml-1 shadow"
            @click="updateIncentiveOtProcess('reject')"
            :disabled="isRejectLoading"
          >
            <span v-if="isRejectLoading"
              ><feather-icon icon="RotateCcwIcon" size="14" />
              กำลังตีกลับใบเบิก...</span
            >
            <span v-else>Reject</span>
          </b-button>
        </div>

          <b-button
            variant="outline-dark"
            class="ml-1 shadow"
            @click="$router.push('/accounting/admin')"
            ><feather-icon icon="ArrowLeftIcon" size="14" /> ย้อนกลับ
          </b-button>
        </b-form>
      </b-col>
    </b-row>
    <!-- End Toolbar-->

    <b-row>
      <b-col>
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span
              ><strong>สถานะใบเบิก : </strong>
              {{ incentiveOtData.processName }}</span
            >
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!--START รายละเอียดระยะทาง -->
    <b-card>
      <b-row class="mb-1">
        <b-col
          ><h3><b>รายละเอียดระยะทาง</b></h3></b-col
        >
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            style="font-size: 12px"
            class="shadow"
            small
            striped
            bordered
            hover
            responsive
            :items="incentiveDetailList"
            :fields="FieldIncentiveDetail"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="isIncDetailLoading"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            
            <template v-slot:head(checked)="data">               
              <b-form-checkbox                 
                  v-model="isSelectIncDetailAll"
                ></b-form-checkbox>
            </template>

            <template #cell(checked)="row">
              <center>
                <b-form-checkbox
                  v-model="selectedIncDetailsHolder[row.item.id]"
                  @change="onSelectIncDetail(row.item)"
                ></b-form-checkbox>
              </center>
            </template>

            <template #cell(no)="row">
              <center>{{ row.index + 1 }}</center>
            </template>

            <template #cell(branchCode)="row">
              <b-badge
                :title="row.item.ticketNo"
                :variant="row.item.isBackHome == 1 ? 'secondary' : 'success'"
                >{{ row.item.branchCode }}</b-badge
              >
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b>ยอดจำนวนเงินทั้งหมด : {{ summaryIncDetailFooter }} บาท</b>
    </b-card>
    <!--END รายละเอียดระยะทาง -->

    <!--START รายละเอียดการเคลียร์ใบเสร็จ -->
    <b-card class="mt-2">
      <b-row class="mb-1">
        <b-col
          ><h3><b>รายละเอียดการเคลียร์ใบเสร็จ</b></h3></b-col
        >
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            style="font-size: 12px"
            class="shadow"
            small
            striped
            bordered
            hover
            responsive
            :items="clearBillList"
            :fields="fieldsClearBill"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="isIncClearBillLoading"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>
            <template #cell(no)="row">
              <center>{{ row.index + 1 }}</center>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b>ยอดจำนวนเงินทั้งหมด : {{ summaryIncClearBillFooter }} บาท</b>
    </b-card>
    <!--END รายละเอียดการเคลียร์ใบเสร็จ -->
  </div>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
  BCard,
  BSpinner,
  BAlert,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//FIELD
import FieldIncentiveDetail from "./field/incentiveDetail";
import FieldClearBill from "./field/clearbillDetail";

//SERVICE
import IncentiveOTService from "@/services/incentiveOT";

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    BCard,
    BForm,
    BAlert,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      kiloRatioSelected:null,
      // kiloRatioSelected: { title: 1, value: 1 },
      kiloRatioOption: [
        { title: 1.0, value: 1.0 },
        { title: 1.5, value: 1.5 },
        { title: 2.0, value: 2.0 },
        { title: 2.5, value: 2.5 },
        { title: 3.0, value: 3.0 },
        { title: 3.5, value: 3.5 },
      ],
      selectedIncDetails: [],
      selectedIncDetailsHolder:{},
      isSelectIncDetailAll:false,
      FieldIncentiveDetail: FieldIncentiveDetail,
      fieldsClearBill: FieldClearBill,
      incentiveDetailList: [],
      clearBillList: [],
      incentiveOtData: {},
      isIncDetailLoading: false,
      isIncClearBillLoading: false,
      isRecalKmLoading: false,
      isAprroveLoading: false,
      isRejectLoading: false,
    };
  },
  async created() {
    const userLoginData = localStorage.getItem("userData");
    const userData = JSON.parse(userLoginData);
    this.userLogin = userData;

    //WHEN EDIT MODE
    const paramIncentiveId = this.$route.params.incentiveId;
    if (paramIncentiveId) {
      this.getIncentiveOtById(paramIncentiveId);
      this.getIncentiveDetail(paramIncentiveId);
      this.getIncentiveClearBill(paramIncentiveId);
    }
  },
  computed: {
    summaryIncDetailFooter() {
      let list = this.incentiveDetailList;
      return list.reduce((acc, value) => acc + value.feeOilGas, 0);
    },
    summaryIncClearBillFooter() {
      return this.clearBillList.reduce((acc, value) => acc + value.amount, 0);
    },
    accountAdminPermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("AC002")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
  },

  watch: {
    isSelectIncDetailAll(currValue) {
      if(currValue){
        this.selectIncDetailAll()
      }else{
        this.setIncDetailHolder(this.incentiveDetailList)
        this.selectedIncDetails = []
      }
        
    },
  },
  methods: {
    async getIncentiveOtById(incentiveOtId) {
      try {
        const result = await IncentiveOTService.GetIncentiveOTById(
          incentiveOtId
        );
        this.incentiveOtData = result.data;
      } catch (error) {
        alert("Load Incentive Ot Data Error");
        console.log(error);
      }
    },
    async getIncentiveDetail(incentiveOtId) {
      this.isIncDetailLoading = true;
      const result = await IncentiveOTService.GetIncentiveDetailList(
        incentiveOtId
      );
      this.setIncDetailHolder(result.data)
      this.incentiveDetailList = result.data;
      this.isIncDetailLoading = false;
    },
    async getIncentiveClearBill(incentiveOtId) {
      this.isIncClearBillLoading = true;
      const result = await IncentiveOTService.GetIncentiveAdminClearBillList(
        incentiveOtId
      );
      this.clearBillList = result.data;
      this.isIncClearBillLoading = false;
    },

    async computeRecalKm() {
      if(this.selectedIncDetails.length == 0){
        alert("กรุณากรอกค่ากิโลที่ต้องการคำนวณ")
        return 
      }
      this.isRecalKmLoading = true;
      const incDetailSelected = this.selectedIncDetails;
      const kiloValue = this.kiloRatioSelected;
      const paramIncentiveId = this.$route.params.incentiveId;

      let output = [];
      incDetailSelected.map((item) => {
        let fomula = item.ditstanceResult * kiloValue ;
        output.push({ id: item.id, value: fomula });
      });

      const result = await IncentiveOTService.RecalKm(output);
      if (result.status == "success") {
            this.isSelectIncDetailAll = false
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Accounting`,
                icon: "CheckIcon",
                variant: "success",
                text: `คำนวณค่ากิโลเรียบร้อย`,
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Accounting`,
                icon: "XIcon",
                variant: "danger",
                text: `คำนวณค่ากิโลผิดพลาด`,
              },
            });
          }


      await this.getIncentiveDetail(paramIncentiveId);
      this.selectedIncDetails = [];
      this.isRecalKmLoading = false;
    },

    async updateIncentiveOtProcess(type) {
      const incOt = this.incentiveOtData;

        if (type == "approve") {
          const isConfirm = confirm("ต้องการอนุมัติใบเบิกหรือไม่ ?");
          if (!isConfirm) return false;

          this.isAprroveLoading = true;
          const processNo = incOt.processNo>=5 ? 5 : incOt.processNo+ 1;
          const result = await IncentiveOTService.UpdateIncentiveOTAdminProcess(
            { processNo },
            incOt.id
          );
          if (result.status == "success") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Accounting`,
                icon: "CheckIcon",
                variant: "success",
                text: `อนุมัติเรียบร้อย`,
              },
            });
            this.$router.push("/accounting/admin")
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Accounting`,
                icon: "XIcon",
                variant: "danger",
                text: `อนุมัติผิดพลาด`,
              },
            });
          }

          this.isAprroveLoading = false;
        } else {
          const isConfirm = confirm("ต้องการตีกลับใบเบิกหรือไม่ ?");
          if (!isConfirm) return false;

          this.isRejectLoading = true;
          const result = await IncentiveOTService.UpdateIncentiveOTAdminProcess(
            { processNo: 0 },
            incOt.id
          );

          if (result.status == "success") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Accounting`,
                icon: "CheckIcon",
                variant: "success",
                text: `ตีกลับใบเบิกเรียบร้อย`,
              },
            });

            this.$router.push("/accounting/admin")
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Accounting`,
                icon: "XIcon",
                variant: "danger",
                text: `ตีกลับใบเบิกผิดพลาด`,
              },
            });
          }

          this.isRejectLoading = false;
        }

        // const paramIncentiveId = this.$route.params.incentiveId;
        // this.getIncentiveOtById(paramIncentiveId);
     
    },
    onSelectIncDetail(item) {
      const incDetails = this.selectedIncDetails;
      const finded = incDetails.find((f) => f.id == item.id) || {};

      //IS FOUND ITEM
      if (Object.keys(finded).length > 0) {
        //REMOVE
        this.selectedIncDetailsHolder = {...this.selectedIncDetailsHolder, [item.id]:false}
        this.selectedIncDetails = incDetails.filter((f) => f.id !== item.id);
      } else {       
        this.selectedIncDetails.push(item);
      }
    },
    selectIncDetailAll(){
      this.setIncDetailHolder(this.incentiveDetailList, true)
      this.selectedIncDetails = this.incentiveDetailList
    },
    setIncDetailHolder(list, status=false){
      let obj = {}
      for (let index = 0; index < list.length; index++) {
        const item = list[index];
        obj = {...obj, [item.id]:status}
      }

      this.selectedIncDetailsHolder = obj
    },
   
  },
  mounted() {},
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
