import { BaseUrl } from "./ApiUrl";
import AXIOS_SERVICE from "../utils/AxiosUtil";

const IncentiveOTService = () => {};

//INCENTIVE OT [USER]
IncentiveOTService.GetIncentiveOTListByEmp = async (payload, employeeId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/user/incentiveOt/employee/${employeeId}`,
    payload
  );
};

//SEARCH INCENTIVE OT [USER]
IncentiveOTService.SearchIncentiveOt = async (querystring="") => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/user/incentiveOt/employee/search?otherNo=${querystring}`,
    {}
  );
};

IncentiveOTService.GetIncentiveOTById = async (incentiveOtId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/user/incentiveOt/${incentiveOtId}`,
    {}
  );
};

IncentiveOTService.InsertIncentiveOT = async (payload) => {
  return await AXIOS_SERVICE(
    "post",
    `${BaseUrl}/accounting/user/incentiveOt`,
    payload
  );
};

IncentiveOTService.UpdateIncentiveOT = async (payload, incentiveOtId) => {
  return await AXIOS_SERVICE(
    "put",
    `${BaseUrl}/accounting/user/incentiveOt/${incentiveOtId}`,
    payload
  );
};

IncentiveOTService.DeleteIncentiveOT = async (incentiveOtId) => {
  return await AXIOS_SERVICE(
    "delete",
    `${BaseUrl}/accounting/user/incentiveOt/${incentiveOtId}`,
    {}
  );
};

//INCENTIVE OT DETAIL [USER]
IncentiveOTService.GetIncentiveDetailList = async (incentiveOtId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/user/incentiveDetail/incentiveOt/${incentiveOtId}`,
    {}
  );
};

IncentiveOTService.CalKilo = async (payload) => {
  return await AXIOS_SERVICE(
    "post",
    `${BaseUrl}/accounting/user/incentiveDetail/cal/kilo`,
    payload
  );
};
IncentiveOTService.InsertIncentiveDetail = async (payload) => {
  return await AXIOS_SERVICE(
    "post",
    `${BaseUrl}/accounting/user/incentiveDetail/`,
    payload
  );
};

IncentiveOTService.UpdateIncentiveDetail = async (
  payload,
  incentiveDetailId
) => {
  return await AXIOS_SERVICE(
    "put",
    `${BaseUrl}/accounting/user/incentiveDetail/${incentiveDetailId}`,
    payload
  );
};

IncentiveOTService.DeleteIncentiveDetail = async (
  incentiveDetailId,
  ticketNo
) => {
  return await AXIOS_SERVICE(
    "delete",
    `${BaseUrl}/accounting/user/incentiveDetail/${incentiveDetailId}/${ticketNo}`,
    {}
  );
};

//INCENTIVE OT CLEARBILL [USER]
IncentiveOTService.GetIncentiveClearBillList = async (incentiveOtId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/user/incentiveClearBill/incentiveOt/${incentiveOtId}`,
    {}
  );
};

IncentiveOTService.GetIncentiveClearBillById = async (incentiveClearBillId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/user/incentiveClearBill/${incentiveClearBillId}`,
    {}
  );
};

IncentiveOTService.InsertIncentiveClearBill = async (payload) => {
  return await AXIOS_SERVICE(
    "post",
    `${BaseUrl}/accounting/user/incentiveClearBill/`,
    payload
  );
};

IncentiveOTService.UpdateIncentiveClearBill = async (
  payload,
  incentiveClearBillId
) => {
  return await AXIOS_SERVICE(
    "put",
    `${BaseUrl}/accounting/user/incentiveClearBill/${incentiveClearBillId}`,
    payload
  );
};

IncentiveOTService.DeleteIncentiveClearBill = async (incentiveClearBillId) => {
  return await AXIOS_SERVICE(
    "delete",
    `${BaseUrl}/accounting/user/incentiveClearBill/${incentiveClearBillId}`,
    {}
  );
};

// ADMIN ===============================================================
//INCENTIVE OT [ADMIN]
IncentiveOTService.GetIncentiveOTAdminListByEmp = async (
  payload,
  employeeId = 0,
  queryString=""
) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/admin/incentiveOt/employee/${employeeId}?dateSearch=${queryString}`,
    payload
  );
};

IncentiveOTService.GetIncentiveAdminDetailList = async (incentiveOtId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/admin/incentiveDetail/incentiveOt/${incentiveOtId}`,
    {}
  );
};

IncentiveOTService.UpdateIncentiveOTAdminProcess = async (payload, incentiveOtId) => {
  return await AXIOS_SERVICE(
    "put",
    `${BaseUrl}/accounting/admin/incentiveOt/${incentiveOtId}`,
    payload
  );
};

//INCENTIVE OT DETAIL [ADMIN]
IncentiveOTService.RecalKm = async (payload) => {
  return await AXIOS_SERVICE(
    "put",
    `${BaseUrl}/accounting/admin/incentiveDetail/recal-km`,
    payload
  );
};

//INCENTIVE OT CLEARBILL [ADMIN]
IncentiveOTService.GetIncentiveAdminClearBillList = async (incentiveOtId) => {
  return await AXIOS_SERVICE(
    "get",
    `${BaseUrl}/accounting/admin/incentiveClearBill/incentiveOt/${incentiveOtId}`,
    {}
  );
};
export default IncentiveOTService;
