export default [
  {
    key: "checked",
    label: "#",
    thStyle:{color:"white",width: "2% !important"}
  },  
  {
    key: "no",
    label: "ลำดับ",
    thStyle:{color:"white",}
  },
  {
    key: "date",
    label: "วันที่",
    sortable: true,
    thStyle:{color:"white",width: "7% !important",}
  },
  { key: "branchCode", label: "รหัสสาขา", sortable: true , thStyle:{color:"white"}},
  { key: "locationForm", label: "จาก", sortable: true ,thStyle:{color:"white",width: "15% !important",}},
  { key: "locationTo", label: "ถึง", sortable: true , thStyle:{color:"white"}},
  { key: "distanceFrom", label: "เลขไมล์เริ่มต้น", sortable: false , thStyle:{color:"white"}},
  { key: "distanceTo", label: "เลขไมล์สิ้นสุด", sortable: false , thStyle:{color:"white"}},
  { key: "ditstanceResult", label: "รวมระยะทาง", sortable: false , thStyle:{color:"white"}},
  { key: "gps", label: "รวมระยะทาง GPS", sortable: false , thStyle:{color:"white"}},
  { key: "avg", label: "รวมระยะทาง Avg", sortable: false , thStyle:{color:"white"}},
  { key: "expressPosition", label: "ค่าทางด่วน", sortable: false , thStyle:{color:"white"}},
  { key: "feeOilGas", label: "จำนวนเงิน", sortable: false , thStyle:{color:"white"}},
  { key: "ticketDescription", label: "รายละเอียดของงาน", sortable: false , thStyle:{color:"white"}},  
  
];
