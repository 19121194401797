export default [
  {
    key: "no",
    label: "ลำดับ",
    thStyle:{color:"white",width: "2% !important"}
  },
  {
    key: "date",
    label: "วันที่",
    sortable: true,
    thStyle:{color:"white",width: "7% !important",}
  },

  { key: "description", label: "รายละเอียดของงาน", sortable: false , thStyle:{color:"white"}},  
  { key: "billNo", label: "	บิลเลขที่", sortable: false , thStyle:{color:"white"}},  
  { key: "amount", label: "	จำนวนเงิน", sortable: false , thStyle:{color:"white"}},  
  
];
